@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Rubik:wght@300&display=swap');

:root {
    --font-body: 'Montserrat', sans-serif;
    --font-size-body: 20px;
    --font-size-header-1: 24px;
    --font-size-header-2: 30px;
    --font-size-header-3: 50px;
    --font-size-filters: 16px;
}

body {
    font: var(--font-body);
    margin: 20px;
    background-color: #181818;
    color: #fff;
}

.overlay-dismiss {
    display: inline-block;
    height: 100%;
    width: 100%;
    position: absolute;
}

hr {
    border: 2px solid #333333;
}

.about {

}

.about .portrait{
    width: 100%;
}

.portrait .img{
    float: left;
    height: auto;
    background-color: #202020;
    padding: 10px;
    border-radius: 10px;
    max-height: 750px;
    display: block;
    margin-bottom: 3%;
    object-fit: cover;
    /*object-position: 0cm -2cm ;*/
    max-width: 100%;
}

.about .aboutme .description{
    font-family: var(--font-body);
    font-style: normal;
    line-height: 135%;
    font-size: var(--font-size-body);
    line-height: 150%;
    color: rgb(255, 255, 255);
    display: flex;
    width: 100%;    
}

.about .aboutme .contact{
    font-family: var(--font-body);
    font-style: normal;
    font-weight: normal;
    font-size: var(--font-size-body);
    line-height: 150%;
    color: rgb(255, 255, 255);
    display: flex;
    width: 100%;    
}

.about .aboutme .links{
    font-family: var(--font-body);
    font-style: normal;
    font-weight: normal;
    line-height: 15%;
    font-size: var(--font-size-body);
    line-height: 10%;
    color: rgb(255, 255, 255);
    display: flex;
    width: 100%;   
}

.about .aboutme .mail a{
    border: none;
    text-align: left;
    text-decoration: underline;
    color: #6da8ca;
    background-color: transparent;
    z-index: 0;
    background:none;
    border:none;
    margin:0;
    padding:0;
}

.about .aboutme .mail a:hover{
    color: #ffffff;
}


.about .aboutme .header{
    font-family: var(--font-body);
    font-style: normal;
    line-height: 135%;
    font-size: var(--font-size-body);
    line-height: 150%;
    color: rgb(255, 255, 255);
    display: flex;
    font-weight:bold;
    width: 100%;
}

.contact{
    display: flex;
    width: 100%;
    font-family: var(--font-body);
    font-style: normal;
    font-weight:bold;
    text-align: center;
}

.columnCV {
    flex-direction: column;
    width: 100%;
}

.cv{
    font-family: var(--font-body);
    font-size: var(--font-size-header-1);
    text-align: left;
    margin-top: 2%;
    margin-bottom: 20%;
    width: 100%;
    display: flex;
    background-color: #202020;
    padding: 20px;
    border-radius: 10px;
    max-width: 100%;
}

.category{
    font-family: var(--font-body);
    font-size: var(--font-size-header-1);
    text-align: left;
    font-weight:bold;
    margin-top: 7%;
}

.cv .header{
    float: right;
    font-family: var(--font-body);
    font-size: var(--font-size-header-1);
    text-align: left;
    padding-bottom: 3px;
    font-weight:bold;
}

.cvEntry{
    width: 100%;
    margin-bottom: 2%;
}

.position{
    width: 70%;
    float: right;
    font-family: var(--font-body);
    font-size: var(--font-size-body);
    text-align: left;
    padding-bottom: 3px;
    font-weight:bold;
}

.company {
    width: 70%;
    float: right;
    font-family: var(--font-body);
    font-size: var(--font-size-body);
    border: none;
    text-align: left;
    text-decoration: none;
    padding-bottom: 3px;
    color:#6da8ca;
}

.company a:link {
    color: #6da8ca;
    text-decoration: none;
}

.company a:visited {
    color: #6da8ca;
    text-decoration: none;
}

.company a:hover {
    color: #ffffff;
    text-decoration: none;
}

.company a:active {
    text-decoration: none;
}

.company .a{
    color:#ffffff;
}

.durtation{
    
    width: 30%;
    font-family: var(--font-body);
    font-size: var(--font-size-body);
    border: none;
    text-align: left;
    text-decoration: none;
}

.skill{
    /*margin-left: 5%;*/
    font-family: var(--font-body);
    font-size: var(--font-size-body);
}

.score{
    width: 13%;
    float: right;
    margin-right: 50%;
    height: auto;
}

.project {
    /*display: inline-block;
    height: 100%;
    width: 70%;
    margin-left: 27%;
    margin-top: 3%;
    position: relative;*/
    padding-top: 50px;
}

.column {
    flex-direction: row;
    width: 100%;
}

.navigation {
    display: none;
}

.main-image {
    max-width: 100%;
}

.main-image .img img {
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    float: left;
}

.sub-image img {
    width: 100%;
    margin-bottom: 5%;
    height: auto;
}

.project .header {
    float: left;
    height: auto;
    width: 100%;
    margin-bottom: 1%;
    color: #fff;
    /*display: block; bc flex*/
}

.project .header .title {
    float: left;
    min-width: 50%;
    margin-top: calc(6% - 1vw);;
    height: auto;
    font-family: var(--font-body);
    font-size: var(--font-size-header-2);
    color: #fff;
    font-weight:bold;
}

.project .header .links {
    margin-top: 1%;
    text-align: right;
    float: right;
    width: 40%;
    height: auto;
    padding-bottom: 0%;
}

.project .links p {
    font-family: var(--font-body);
    font-size: var(--font-size-body);
    color: rgb(255, 255, 255);
}

.project .descriptionbox {
    float: left;
    max-height: 30%;
    position: static;
}

.project .descriptionbox .description {
    float: left;
    max-height: 30%;
    position: static;
}

.project .descriptionbox .description p {
    font-family: var(--font-body);
    font-style: normal;
    line-height: 135%;
    font-size: var(--font-size-body);
    /*font-size:calc(90% + 0.3vw);*/
    color: rgb(221, 221, 221);
}

.project .descriptionbox .collaborators {
    float: left;
    max-height: 30%;
    position: static;
    overflow-wrap: break-word;
    color: rgb(221, 221, 221);
}

.project .descriptionbox .collaborators p {
    text-align: left;
    font-family: var(--font-body);
    font-size: var(--font-size-body);
    color: rgb(221, 221, 221);
}

.sideBar {
    height: calc(100% - 2*(60px + 1.4vw) - 1%);
    /* 100% Full-height */
    width: 17%;
    min-width: 250px;
    /*width: calc(125px + 11vw);*/
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 0;
    /* Stay on top */
    top: calc(2*(60px + 1.4vw));
    /*bottom: calc(80px + 1.4vw);*/
    bottom: 0;
    /* Stay at the top */
    left: 0;
    background-color: #202020;
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */
    /*padding-top: 30px;*/
    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
    border-radius: 20px;
}

.Flipped{
    direction: rtl;
}

::-webkit-scrollbar {
    background: transparent;
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: #3e6074;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
    
  }

.about-button {
    z-index: 0;
    top: 0;
    position: fixed;
    width: 17%;
    left: 0;

    padding-left: 7%;
    padding-left: 0;
    padding-right: 0;
    font-family: var(--font-body);
    font-weight:bold;
    font-size: var(--font-size-header-3);
    background-color: #181818;
    color: #6da8ca;
    border: none;
    text-align: left;
    text-decoration: none;

    /*display: block; bc flex*/
    height: calc(2*(60px + 1.4vw));
    z-index: 1;
    justify-content: space-between;
}

.about-button:hover{
    background-color: #181818;
    color:#979797;
    z-index: inherit;
    cursor: pointer;
}

.about-button .about-button--on {
    background-color: inherit;
    padding-left: 7%;
    z-index: inherit;
}

.about-button:hover .about-button--on {
    background-color:transparent;
    z-index: inherit;
}

.hover-button {
    padding-left: 0;
    padding-right: 0;
    font-family: var(--font-body);
    font-weight:bold;
    font-size: var(--font-size-header-2);
    background-color: transparent;
    color: #c5c5c5;
    border: none;
    text-align: left;
    text-decoration: none;
    
    /*display: block; bc flex*/
    height: calc(55px + 2.5vw);
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    z-index: 0;
}





.hover-button:hover{
    position: relative;
    background-color: transparent;
    background-image: var(--hover--image);
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    /*filter: grayscale(0.7);*/
}

.hover-button:hover::before{
    content: '';
    background-color: hsla(0, 0%, 9%);
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;
    opacity: 0.85;
}

.hover-button .hover-button--on {
    background-color: transparent;
    padding-left: 7%;
    z-index: 1;
}

.hover-button:hover .hover-button--on {
    background-color: transparent;
    /*background-color: #181818;*/
    z-index: 1;
}

.tags{
    margin-top: 10px;
    width:95%;
    height:30%;
}

.tag{
    background-color: #181818;
    color:#979797;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 5px;
    float: left;
    font-family: var(--font-body);
    font-size: var(--font-size-filters);
    border: none;
    text-align: left;
    text-decoration: none;
    z-index: 5;
    cursor: pointer;
}

.tags .tag:hover {
    background-color: #202020;
    color:#6da8ca;
}

.filters {
    background-color: #333333;
    /*height: calc(16px + 1.2vw);*/
    text-align: left;
    direction: ltr;
    font-family: var(--font-body);
    font-size: var(--font-size-filters);
    padding-left: 7%;
    padding-top: 3%;
    width: 100%;
    float:left;
    height: fit-content;
    padding-bottom: 15px;
    line-height: 200%;
}

.filtertag {
    background-color: #181818;
    color: #ffffff;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 5px;
    font-family: var(--font-body);
    font-size: var(--font-size-filters);
    border: none;
    text-align: left;
    text-decoration: none;
    z-index: 5;
    width:fit-content;
    cursor: pointer;
    display: inline-block;
}

.tags .filtertag:hover {
    background-color: #181818;
    color:#6da8ca;
    text-decoration:line-through;
}

.filtertag .clearIcon{
    display: none;
}

.filtertag:hover .clearIcon{
    display: inline;
}

.video-responsive {
    float: left;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 56.31%; /*32.1*/
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

html {
    scroll-behavior: smooth;
}

a:link {
    color: #ffffff;
    text-decoration: none;
}

a:visited {
    color: #ffffff;
    text-decoration: none;
}

a:hover {
    color: #6da8ca;
    text-decoration: none;
}

a:active {
    text-decoration: none;
}


/* modal */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.8);
    display: flex;
    align-items: center;
    z-index:900;
}

.overlay img{
    display: block;
    max-height: 90%;
    max-width: 90%;
    width: 90%;
    height: auto;
    object-fit: contain;
    margin: 60px auto;
    /*box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);*/
    z-index: 900;
}


  .overlay > span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: var(--font-size-header-2);
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
  }
  .overlay-arrows_left {
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 999;
  }
  
  .overlay-arrows_left svg {
    width: 50px;
    height: 50px;
  }
  
  .overlay-arrows_right svg {
    width: 50px;
    height: 50px;
  }
  
  .overlay-arrows_right {
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;
  }

  @media only screen and (min-width:1000px) {
    :root {
        --font-size-body: 14px;
        --font-size-header-1: 20px;
        --font-size-header-2: 22px;
        --font-size-header-3: 40px;
        --font-size-filters: 12px;
    }

    .column {
        flex-direction: column;
        width: 100%;
    }

    .columnCV {
        flex-direction: column;
        width: 100%;
    }

    .navigation {
        display: block;
    }

    .project {
        display: flex;
        padding-top: 0.7%; /*35px*/
    }

    .navPlaceholder {
        width: 25%;
        min-width: 250px;
    }

    .colMain {
        width: 50%;
        padding-left: 8%;
        padding-right: 2%;
    }

    .colSub {
        width: 25%;
        padding-left: 1%;
        padding-right: 10%;
    }

    hr {
        display: none;
    }

    .about {
        padding-left: 8%;
        display: flex;
    }

    .portrait .img{
        margin-top: 7%;
        width: auto;
        min-width: 20%;
    }

    .aboutme {
        width: 35%;
        padding-right: 2%;
    }

    .cv {
        width: 35%;
        margin-left: 3%;
        margin-right: 10%;
    }

    .vl {
        border-left: 3px solid #6da8ca;
        height: 105%;
        position: absolute;
        left: 65%;
        top: 0;
    }

    .about .aboutme .description{
        width: 90%;    
    }
    
    .about .aboutme .header{
        width: 90%;
    }
}

@media only screen and (min-width:2000px) {
    :root {
        --font-size-body: 20px;
        --font-size-header-1: 24px;
        --font-size-header-2: 30px;
        --font-size-header-3: 50px;
        --font-size-filters: 16px;
    }

    .project {
        display: flex;
        padding-top: 35px;
    }
}

@media only screen and (min-width:3000px) {
    :root {
        --font-size-body: 26px;
        --font-size-header-1: 28px;
        --font-size-header-2: 36px;
        --font-size-header-3: 6px;
        --font-size-filters: 19px;
    }
}